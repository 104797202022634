import React, { Component } from 'react';
import styled from 'styled-components';
import {Link } from 'gatsby';

class Spotlight extends Component {
    render(){        
        const data = this.props.data;
        
        const SpotlightItems = data.allWpShowcaseItem.nodes.map((node) => {            
         
         console.log(node);
        const fImage = node.featuredImage.node.localFile.childImageSharp.fixed;
        const bgImgBack = node.spotlight.backgroundImage[0].localFile.childImageSharp.fixed;
        const logoImg = node.spotlight.logo.localFile.childImageSharp.fixed;
        let fImageAttr;
        let bgImgBackAttr;
        if (fImage){fImageAttr = fImage.src}
        if (bgImgBack){bgImgBackAttr = bgImgBack.src}

           return (    
                <SpotLightItem  key={node.id}>
                    <div className="flipcard-col">
                    <div className="flipcard flipcard-front" style={{backgroundImage: `url(${fImageAttr})`}}>
                            <div className="flipcard-content flipcard-content-front">
                                <div className="flipcard-image">
                                <Link to={'/our-work/' + node.slug + '/'} className="flipcard-link">
                                        <span className="flipcard-img-wrapper">
                                        {fImage && 
                                            <img src={fImage.src} alt={node.title} className="flipcard-img"/>
                                        }
                                        </span>
                                        <span className="flipcard-link-text">
                                            <span className="flipcard-link-title">
                                                <em dangerouslySetInnerHTML={{ __html: node.title }}/>
                                            </span>
                                            <span className="flipcard-link-desc">
                                            
                                            </span>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="flipcard flipcard-back" style={{backgroundImage: `url(${bgImgBackAttr})`}} >
                            <div className="flipcard-content flipcard-content-back">
                            <Link to={'/our-work/' + node.slug + '/'} className="flipcard-link">
                                    <span className="flipcard-logo">                                         
                                        {logoImg && 
                                            <img src={logoImg.src} alt={node.title} className="logo-image"/>
                                        }                                         
                                    </span>
                                </Link>
                            </div>
                        </div>
                        
                    </div>
                </SpotLightItem>
            );
        });

        return (  
            <SpotLightWrapper>
                {SpotlightItems} 
            </SpotLightWrapper> 
        );
    }
}

export default Spotlight;

const SpotLightWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    margin: 1.5rem -20px;
    padding:  0;
    @media (max-width: 767px) {
        margin: 1.8rem auto;
    }
`
const SpotLightItem = styled.div`
    flex: 1 1 30%;
    max-width: 30%;
    margin: 0;
    overflow: hidden;
    .flipcard-col {
        position: relative;
        min-height: 350px;
        transform-style: preserve-3d;
        .flipcard {
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: 50% 50%;
            position: absolute;
            top: 0;
            backface-visibility: hidden;
            transform-style: preserve-3d;
            z-index: 1;
            display: flex;
            perspective: inherit;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-size: 14px;
        }

        .flipcard-content {
            padding: 3rem;
            width: 100%;
            position: relative;
            transform-style: preserve-3d;
            backface-visibility: hidden;
            box-sizing: border-box;
            z-index: 2;
        }

        .flipcard-content-back {
            padding: 0;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .flipcard,
        .flipcard-content {
            transition-property: all;
            transition-duration: .6s;
            transition-timing-function: cubic-bezier(.5,.3,.3,1);
        }

        .flipcard-front {
            transform: rotateY(0deg);
            &:before {
                content: open-quote;
                text-indent: -9999px;
                display: block;
                background: rgba(66,85,99,.9);
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .flipcard-back {
            transform: rotateY(180deg);
            &:before {
                content: open-quote;
                text-indent: -9999px;
                display: block;
                background: rgba(66,85,99,.95);
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
            }
        }

        .flipcard-content-front {
            transform: translateX(0px) translateZ(0px) scale(1);
        }

        .flipcard-content-back {
            transform: translateX(50%) translateZ(80px) scale(.9);
            opacity: 0;
        }

        .flipcard-link {
            flex: 0 0 100%;
            max-width: 100%;
            position: relative;
            color: #ffffff;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            width: 100%;
            justify-content: center;
            span {
                display: block;
            }
        }
        .flipcard-logo {
            img {
                max-width: 100%;
                height: auto;
            }
        }
        .flipcard-image {
            display: flex;
            justify-content: center;
            align-content: center;
            width: 100%;
            height: 100%;

            .flipcard-link {
                .flipcard-img-wrapper
                {                    
                    height: 270px;
                    text-align: center;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    position: relative;
                }
                .flipcard-img {
                    width: auto;
                    height: 100%;
                    flex: 0 0 auto;
                    transition: opacity .2s ease-out;
                    backface-visibility: hidden;
                }
            }

            .flipcard-link-text {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 0 20px 20px;
                text-align: left;
                &:before {
                    content: open-quote;
                    text-indent: -9999px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    height: 320%;
                    width: 100.1%;
                    background: linear-gradient(to bottom, rgba(51,63,72,0) 0%,rgba(51,63,72,1) 100%);
                    backface-visibility: hidden;
                }

                .flipcard-link-title {
                    font-size: 16px;
                    font-weight: 700;
                    z-index: 1;
                    position: relative;
                    margin: 0 0 10px 0;
                    text-transform: uppercase;
                    em
                    {
                        display: block;
                        font-style: normal;
                        backface-visibility: hidden;
                        text-shadow: 1px 1px #FF8674;
                    }

                    &:after {
                        content: close-quote;
                        text-indent: -9999px;
                        position: relative;
                        height: 2px;
                        width: 40px;
                        display: block;
                        margin: 10px 0 0 0;
                        transition: all .2s ease-out;
                        z-index: 1;
                        backface-visibility: hidden;
                        background: #FF8674;
                    }
                }
                .flipcard-link-desc {
                    z-index: 1;
                    position: relative;
                }
            }

            &:hover {
                .flipcard-img {
                    opacity: .8;
                }
                .flipcard-link-title:after {
                    width: 80px;
                }
            }
        }

        &:hover {
            .flipcard-front {
                transform: rotateY(-180deg);
            }
            .flipcard-back {
                transform: rotateY(0deg);
            }
            .flipcard-content-front {
                transform: translateX(-50%) translateZ(80px) scale(.9);
                opacity: 0;
            }
            .flipcard-content-back {
                transform: translateX(0px) translateZ(0) scale(1);
                opacity: 1;
            }
        }
    }

    @media only screen 
    and (min-device-width: 1023px) 
    and (max-device-width: 1367px) 
    and (orientation: portrait) { 
        flex: 0 0 45%;
        max-width: 45%;
        margin-bottom: 1.5rem;
    }
    @media (max-width: 1025px) {
        .flipcard-col {
            .flipcard-content {
                    padding: 2rem;
            }
        }
    }

    @media (max-width: 991px) {
        flex: 0 0 45%;
        max-width: 45%;
        margin-bottom: 1.5rem;        
    }
    @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;        
        padding: 0;
        .flipcard-col {
            .flipcard-content {
                    padding: 1.5rem;
            }
        }
    }
`