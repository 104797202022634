import React , { Component } from 'react'
import {Link} from 'gatsby'
import styled from 'styled-components'

class LandingPage extends Component {
    render() {
        return (
        <IntroContent>
            <IntroTitle>SHOWCASING OUR
                <span>MARKETING SOLUTIONS</span>
            </IntroTitle>
            <IntroText>Join us on a journey demonstrating how we can help you build your business with flexible marketing solutions</IntroText>
            <Link to="/our-work/">View All Work</Link>
        </IntroContent>
        );
    }
}

export default LandingPage

const IntroContent = styled.div`
    flex: 0 0 50%;
    max-width: 50%;
    padding-bottom: 3rem;  
    a {
        display: inline-block;
        text-transform: uppercase;
        text-decoration: none;
        margin-right: 1.5rem;
        margin-top: 20px;
        font-size: 16px;
        padding: 20px 40px;
        font-weight: 600;
        color: #ffffff;
        background: #FF8674;
        transition: all .2s ease-in;        
        &:hover {
            background: #768692;
        }       
    }
    @media (min-width: 1600px) {
        padding-bottom: 5rem;
    }


    @media (max-width: 991px) {
        flex: 0 0 60%;
        max-width: 60%;
        background: rgba(255,255,255,0.6);
        padding: 1.5rem;       
    }
    @media (max-width: 767px) {
        flex: 0 0 100%;
        max-width: 100%;       
        width: 100%;
        padding: 1rem;
        a {
             padding: 0.5rem 0.6rem;
            margin-right: 0.75rem;  
        }
    }
`

const IntroTitle = styled.h1`
    font-size: 45px;
    line-height: 1.1;
    text-transform: uppercase;
    color: #333F48;
    font-weight: 700;
    span {
        display: block;
        color: #FF8674;
    }
    @media (max-width: 767px) {
        font-size: 32px;
    }
`
const IntroText = styled.p`
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 20px;
`