import React, { Component } from 'react';
import { navigate } from "gatsby";
import { isLoggedIn } from "../utils/auth";
import styled from 'styled-components';
import LandingPage from '../components/LandingPage';
import Spotlight from '../components/LandingPage/Spotlight';
import Header from '../components/Header';
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

class IndexPage extends Component {
  componentDidMount() {    
    document.body.classList.add("background-blue");
  }

  componentWillUnmount() {
      document.body.classList.remove("background-blue");
  }
  
  render() {
    const isBrowser = typeof window !== `undefined`
    if (!isLoggedIn()) {
      if (!isBrowser) return false
        // If we’re not logged in, redirect to the home page.
        navigate(`/sign-in`)
        return null
    }    
     
  return ( 
  <Layout className="page-wrapper">
    <Header/> 
        <PageWrapper>
        <div className="content-wrapper">         
          <LandingPage />       
          <Spotlight data={this.props.data}/>
        </div> 
        <StaticImage 
            src="../assets/LandingScreenBG.jpg"                        
            as="div"
            placeholder="blurred"
            className="bgImage"  
            alt="Showcasing our marketing solutions"                            
        />
        </PageWrapper>
  </Layout>
   );
  }
}

export default IndexPage;

export const pageQuery = graphql` 
query showcaseSpotlightQuery { 
  allWpShowcaseItem(
    limit: 3 
    sort:{ order: ASC, fields: [date] }               
    filter: {
      spotlight: {
        isFeatured: {eq: true}
      }
    }      
  ) {
    nodes {
      id
      slug
      title
      featuredImage {        
        node {
          localFile {
            childImageSharp {
              fixed(width: 600, height: 400) {
                src
                width
                height
              }
            }
          }
        }
      }
      spotlight {          
        logo {
          localFile {
            childImageSharp {               
              fixed(width: 200, height: 200){
                src
                width
                height
              }
            }
          }
        }
        backgroundImage {
          localFile {
            childImageSharp {               
              fixed(width: 1200, height: 800){
                src
                width
                height
              }
            }
          }
        }
      }
    }
  }
}
`;

const PageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  padding: 7rem 1.8rem 1.8rem 1.8rem;  
  background-color: #e1e6e9;
  z-index:0;
  .bgImage {    
    width: 100%;
    height: 100%;
   /* top: -50px;*/
    top: 0;
    left: 0;
    position: fixed!important;
    z-index: -1;    
  }
  .content-wrapper {
    max-width: 1400px;
    width: 100%;
    margin: 0 auto;
  }
  @media (min-width: 1600px) {
    /*.bgImage {
      top: -100px;
    }*/
    .content-wrapper {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
  @media (max-width: 767px) {
    padding: 5rem 1.2rem 1.2rem 1.2rem;    
  }
  @media only screen 
  and (min-device-width: 375px) 
  and (max-device-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3) { 
    padding: 6rem 1.2rem 1.2rem 1.2rem; 
  }
  @media (max-width: 765px)
  and (min-width: 480px) {
    padding: 6.6rem 1.2rem 1.2rem 1.2rem;
  }
`